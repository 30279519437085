/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'


// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import {ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'

const Hero = styled.section`
  background-size: 100% 400px;

  @media screen and (max-width: 991px) {
    background-size: cover !important;
  }
`

const HeroContent = styled.div`
  padding-top: 200px;
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <Hero
        className="hero-image-small"
        style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}
      >
        <HeroContent className="container">
          <div className="mb-3">
            <ParseContent
              className="color-light text-center text-lg-left"
              content={acf.banner.description}
            />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      <div className="container py-5">
        <div className="px-lg-5">
          <ParseContent content={acf.meta.description} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
          localFile {
            publicURL
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                src
              }
            }
          }
        }
        }

        meta {
          description
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
